'use client';

import type { NavMenuItem, NavMenuItemWithSublinks } from '@features/api/client';
import Link from 'next/link';

import { Collapsible, CollapsibleContent } from '@features/webshop/shared/ui/Collapsible';
import {
	MenubarContent,
	MenubarItem,
	MenubarLabel,
	MenubarMenu,
	MenubarTrigger,
	menuBarClassName,
	menuBarLabelClassName,
} from '@features/webshop/shared/ui/Menubar';
import { CollapsibleTrigger } from '@radix-ui/react-collapsible';
import { IconChevronDown, IconCircleFilled } from '@tabler/icons-react';
import { useWindowSize } from '@uidotdev/usehooks';
import { usePathname } from 'next/navigation';
import { cn } from '@features/shared/utils/utils';
import { useAtom } from 'jotai';
import { isMobileMenuOpenAtom } from './state/isMobileMenuOpenAtom';

type SubNavItemProps = {
	item: NavMenuItem;
	isMobile?: boolean;
};

function SubNavItem({ item, isMobile }: SubNavItemProps) {
	const Item = isMobile ? 'div' : MenubarItem;
	const [_, setIsMenuOpen] = useAtom(isMobileMenuOpenAtom);

	const pathname = usePathname();

	switch (item.type) {
		case 'page_link':
			const isActive = pathname === item.value.page.url;
			return (
				<Link href={item.value.page.url} onClick={() => setIsMenuOpen(false)}>
					<Item className={menuBarClassName}>
						{item.value.display_text}
						{isActive && <IconCircleFilled className="h-1.5 w-1.5 text-primary-blue" />}
					</Item>
				</Link>
			);
		case 'external_link':
			const LinkElement = item.value.link ? 'a' : 'span';
			return (
				<LinkElement href={item.value.link} onClick={() => setIsMenuOpen(false)}>
					<Item className={menuBarClassName}>{item.value.display_text}</Item>
				</LinkElement>
			);
	}
}

type NavItemProps = {
	children: React.ReactNode;
	isActive: boolean;
	shouldNotCloseMenu?: boolean;
};

function NavItem({ children, isActive, shouldNotCloseMenu, ...rest }: NavItemProps) {
	const [_, setIsMenuOpen] = useAtom(isMobileMenuOpenAtom);
	return (
		<li
			{...rest}
			onClick={() => {
				if (shouldNotCloseMenu) return;
				setIsMenuOpen(false);
			}}
			className={cn(
				'w-100 xspy-4 relative flex cursor-pointer select-none justify-between gap-1 whitespace-nowrap border-y-2 border-solid border-transparent px-3 py-2 text-base font-medium text-black xs:px-4 lg:py-6 xl:border-y-[3px]',
				isActive && 'max-xl:font-extrabold xl:border-b-primary-blue'
			)}>
			{children}
		</li>
	);
}

type NavItemWithSubitemsProps = {
	children: React.ReactNode;
	sublinks: Array<NavMenuItem>;
	isActive: boolean;
};

function NavItemWithSubitems({ children, sublinks, isActive }: NavItemWithSubitemsProps) {
	const size = useWindowSize();

	const isMobile = size.width && size.width < 1280;

	if (isMobile)
		return (
			<Collapsible>
				<CollapsibleTrigger className="w-full">
					<NavItem isActive={isActive} shouldNotCloseMenu>
						<>
							{children}
							<IconChevronDown />
						</>
					</NavItem>
				</CollapsibleTrigger>
				<CollapsibleContent className="mb-4 mt-4 border-b border-t border-solid border-gray-600 bg-white pb-4 pt-4 text-black">
					{sublinks?.map((sublink, index) => (
						<span key={sublink.id}>
							{sublink.value.eyebrow_text && (
								<div
									className={menuBarLabelClassName}
									style={{ marginTop: index !== 0 ? '1rem' : 0 }}
									key={`DropdownMenuLabel-${sublink.id}`}>
									{sublink.value.eyebrow_text}
								</div>
							)}
							<SubNavItem
								isMobile={isMobile}
								item={sublink}
								key={`DropdownMenuItem-${sublink.id}`}
							/>
						</span>
					))}
				</CollapsibleContent>
			</Collapsible>
		);

	return (
		<MenubarMenu>
			<MenubarTrigger>
				<NavItem isActive={isActive}>
					<>
						{children}
						<IconChevronDown />
					</>
				</NavItem>
			</MenubarTrigger>
			<MenubarContent>
				{sublinks?.map((sublink, index) => (
					<span key={sublink.id}>
						{sublink.value.eyebrow_text && (
							<MenubarLabel
								key={`DropdownMenuLabel-${sublink.id}`}
								style={{ marginTop: index !== 0 ? '1rem' : 0 }}>
								{sublink.value.eyebrow_text}
							</MenubarLabel>
						)}
						<SubNavItem item={sublink} key={`DropdownMenuItem-${sublink.id}`} />
					</span>
				))}
			</MenubarContent>
		</MenubarMenu>
	);
}

type MenuItemProps = {
	item: NavMenuItemWithSublinks;
};

function MenuItem({ item }: MenuItemProps) {
	const sublinks = item.value?.sublinks || [];
	const hasSublinks = sublinks.length > 0;
	const NavElement = hasSublinks ? NavItemWithSubitems : NavItem;

	const pathname = usePathname();

	switch (item.type) {
		case 'page_link':
			const isActive =
				pathname === item.value.page.url ||
				item.value.sublinks.some((sublink) =>
					'page' in sublink.value ? pathname === sublink.value.page.url : false
				);

			return (
				<MenubarMenu>
					{hasSublinks ? (
						<NavElement sublinks={sublinks} isActive={isActive}>
							{item.value.display_text}
						</NavElement>
					) : (
						<Link href={item.value.page.url}>
							<NavElement sublinks={sublinks} isActive={isActive}>
								{item.value.display_text}
							</NavElement>
						</Link>
					)}
				</MenubarMenu>
			);
		case 'external_link':
			const LinkElement = item.value.link ? 'a' : 'span';
			return (
				<LinkElement title={item.value.display_text} href={item.value.link || undefined}>
					<NavElement sublinks={sublinks} isActive={false}>
						{item.value.display_text}
					</NavElement>
				</LinkElement>
			);
	}
}

export default MenuItem;
